import classNames from "classnames";
import styles from './Hero.module.scss'

const HeroTitle = ({
                     children,
                     white,
                     primary,
                     secondary,
                   }) => {
  const classes = classNames([
    styles.HeroTitle,
    {[styles.HeroTitleWhite] : white},
    {[styles.HeroTitlePrimary] : primary},
    {[styles.HeroTitleSecondary] : secondary},
  ])
  return <h1 className={classes}>{children}</h1>
}

export default HeroTitle;
