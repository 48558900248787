import styles from "./Hero.module.scss";
import layoutStyles from "../../Layout/Layout.module.scss";
import buttonStyles from "../../../styles/Button.module.scss";
import Translator from "../../Translator/Translator";
import React from "react";
import ScrollToAnchorLink from "../../ScrollToAnchorLink/ScrollToAnchorLink";
import HeroTitle from "./HeroTitle";
import {Trans, useTranslation} from "react-i18next";
import NavLinkLocale from "../../NavLinkLocale";


const Hero = ({ leftSide, rightSide, className }) => {
  const { t } = useTranslation();

  const defaultLeft = (
    <div className={styles.HeroLogo}>
      <img src="/logo_blue.svg" height={200} width={200} alt="Logo" />
      <h3>Michalina<br/>Zgódka-Bilous</h3>
    </div>
  )

  const defaultRight = (
    <div className={styles.HeroTextContainer}>
      <div className={styles.HeroTextBody}>
        <p>{t('Warszawa, Białołęka, Tarchomin')}</p>
        <HeroTitle white>{t('Tłumacz przysięgły języka ukraińskiego')}</HeroTitle>
        <NavLinkLocale to="/offer" className={buttonStyles.Button}>{t('Oferta')}</NavLinkLocale>
      </div>
      <div className={styles.HeroTextFooter}>
        <ScrollToAnchorLink
          target="section-contact"
          title={t('Formularz kontaktowy')}
        />
        <Translator />
      </div>
    </div>
  )
  return (
    <section className={`${layoutStyles.HomeSection} ${className || ''}`}>
      <div className={styles.HeroImage}>
        <div className={layoutStyles.ContainerHalf}>
          {leftSide || defaultLeft}
        </div>
      </div>
      <div className={layoutStyles.HomeSectionRight}>
        <div className={layoutStyles.ContainerHalf}>
          {rightSide || defaultRight}
        </div>
      </div>
    </section>
  )
}

export default Hero;
