import styles from './ScrollToAnchorLink.module.scss';

const ScrollToAnchorLink = ({target, title}) => {
  const scrollToSection = (event) => {
    if (!event.target.getAttribute('href')) {
      return false;
    }
    const target = document.getElementById(event.target.getAttribute('href').replace('#', ''))
    if (target) {
      event.preventDefault();
      target.scrollIntoView({
        behavior: "smooth"
      });
    }
  }
  return (
    <a href={`#${target}`} onClick={scrollToSection} className={styles.ScrollToAnchorLink}>
      {title}
      <img src="/learn-more-arrows.svg" width="14" height="18" />
    </a>
  )
}

export default ScrollToAnchorLink;
